import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';

export const useFilter = () => {
  // Composables
  const { t } = useI18n();

  const getAggregateFilterOption = () => {
    return [
      {
        key: 'month',
        label: t('global.month'),
      },
      {
        key: 'week',
        label: t('global.week'),
      },
      {
        key: 'day',
        label: t('global.day'),
      },
    ];
  };

  const getAggregationMeter = () => {
    return [
      {
        key: 'meter',
        label: t('global.no'),
      },
      {
        key: 'child_meter',
        label: t('global.yes'),
      },
    ];
  };

  const getAggregationFromDateRange = (options: { start: string; end: string }) => {
    const start = dayjs(options.start);
    const end = dayjs(options.end);

    if (end.diff(start, 'week', true) > 4) {
      return 'week';
    } else {
      return 'day';
    }
  };

  const getDateRangeFromDateAndAggregationLevel = (options: { timestamp: string; aggregationLevel: string }) => {
    const timestamp = dayjs(options.timestamp);
    switch (options.aggregationLevel) {
      case 'week':
        return {
          start: timestamp.startOf('month').format('YYYY-MM-DD'),
          end: timestamp.endOf('month').format('YYYY-MM-DD'),
        };
      case 'day':
        return {
          start: timestamp.format('YYYY-MM-DD'),
          end: timestamp.add(6, 'day').format('YYYY-MM-DD'),
        };
      case 'hour':
        return {
          start: timestamp.startOf('day').toString(),
          end: timestamp.endOf('day').toString(),
        };
      case 'minute':
        return {
          start: timestamp.startOf('hour').toString(),
          end: timestamp.endOf('hour').toString(),
        };
    }
  };

  return {
    getAggregateFilterOption,
    getDateRangeFromDateAndAggregationLevel,
    getAggregationFromDateRange,
    getAggregationMeter,
  };
};
